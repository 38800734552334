/* Variáveis de cor do projeto */
$color-blue-300: #64b5f6;
$color-blue-500: #2196f3;
$color-blue-700: #1976d2;
$color-blue-900: #0d47a1;

$color-orange-a200: #ffab40;
$color-orange-500: #ff9800;
$color-orange-a700: #ff6d00;

$color-blue-grey-800: #37474f;
$color-blue-grey-900: #263238;

$color-grey-50: #fafafa;
$color-grey-100: #f5f5f5;
$color-grey-200: #eeeeee;
$color-grey-300: #e0e0e0;
$color-grey-400: #bdbdbd;
$color-grey-500: #9e9e9e;
$color-grey-600: #757575;
$color-grey-700: #616161;
$color-grey-800: #424242;

$color-white: #fff;
$color-black: #000;

$color-red-500: #f44336;
$color-green-500: #4caf50;
$color-yellow-800: #fbc02d;

// OTHERS
$color-pink-400: #ec407a;
$color-purple-500: #9c27b0;
$color-teal-500: #009688;
$color-whatsapp: #e1ffc8;

// Guide
$color-primary: $color-blue-700;
$color-secondary: $color-blue-grey-800;
$color-accent: $color-orange-500;

// Text recommendation
$color-text-primary: rgba($color-black, 0.87);
$color-text-secondary: rgba($color-black, 0.6);
$color-text-hint: rgba($color-black, 0.38);
$color-dividers: rgba($color-black, 0.12);

$color-text-primary-white: rgba($color-white, 0.7);
$color-text-secondary-white: rgba($color-white, 0.5);
$color-text-hint-white: rgba($color-white, 0.3);
$color-dividers-white: rgba($color-white, 0.12);

// Cores do onibus
$color-bus-disabled-background: #f1f1f1;
$color-bus-disabled-border: #cccccc;
$color-bus-disabled-text: #d9d9d9;

// SHADOW
$color-shadow: rgba(0, 0, 0, 0.24);

// PALETA MATERIAL DESIGN
$color-white: #ffffff;
$color-black: #000000;

// RED
$color-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  a100: #ff8a80,
  a200: #ff5252,
  a400: #ff1744,
  a700: #d50000,
);

$color-red-50: map-get(
  $map: $color-red,
  $key: 50,
);
$color-red-100: map-get(
  $map: $color-red,
  $key: 100,
);
$color-red-200: map-get(
  $map: $color-red,
  $key: 200,
);
$color-red-300: map-get(
  $map: $color-red,
  $key: 300,
);
$color-red-400: map-get(
  $map: $color-red,
  $key: 400,
);
$color-red-500: map-get(
  $map: $color-red,
  $key: 500,
);
$color-red-600: map-get(
  $map: $color-red,
  $key: 600,
);
$color-red-700: map-get(
  $map: $color-red,
  $key: 700,
);
$color-red-800: map-get(
  $map: $color-red,
  $key: 800,
);
$color-red-900: map-get(
  $map: $color-red,
  $key: 900,
);
$color-red-a100: map-get(
  $map: $color-red,
  $key: a100,
);
$color-red-a200: map-get(
  $map: $color-red,
  $key: a200,
);
$color-red-a400: map-get(
  $map: $color-red,
  $key: a400,
);
$color-red-a700: map-get(
  $map: $color-red,
  $key: a700,
);

// PINK
$color-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  a100: #ff80ab,
  a200: #ff4081,
  a400: #f50057,
  a700: #c51162,
);

$color-pink-50: map-get(
  $map: $color-pink,
  $key: 50,
);
$color-pink-100: map-get(
  $map: $color-pink,
  $key: 100,
);
$color-pink-200: map-get(
  $map: $color-pink,
  $key: 200,
);
$color-pink-300: map-get(
  $map: $color-pink,
  $key: 300,
);
$color-pink-400: map-get(
  $map: $color-pink,
  $key: 400,
);
$color-pink-500: map-get(
  $map: $color-pink,
  $key: 500,
);
$color-pink-600: map-get(
  $map: $color-pink,
  $key: 600,
);
$color-pink-700: map-get(
  $map: $color-pink,
  $key: 700,
);
$color-pink-800: map-get(
  $map: $color-pink,
  $key: 800,
);
$color-pink-900: map-get(
  $map: $color-pink,
  $key: 900,
);
$color-pink-a100: map-get(
  $map: $color-pink,
  $key: a100,
);
$color-pink-a200: map-get(
  $map: $color-pink,
  $key: a200,
);
$color-pink-a400: map-get(
  $map: $color-pink,
  $key: a400,
);
$color-pink-a700: map-get(
  $map: $color-pink,
  $key: a700,
);

// PURPLE
$color-purple: (
  50: #f3e5f5,
  100: #e1bee7,
  200: #ce93d8,
  300: #ba68c8,
  400: #ab47bc,
  500: #9c27b0,
  600: #8e24aa,
  700: #7b1fa2,
  800: #6a1b9a,
  900: #4a148c,
  a100: #ea80fc,
  a200: #e040fb,
  a400: #d500f9,
  a700: #aa00ff,
);

$color-purple-50: map-get(
  $map: $color-purple,
  $key: 50,
);
$color-purple-100: map-get(
  $map: $color-purple,
  $key: 100,
);
$color-purple-200: map-get(
  $map: $color-purple,
  $key: 200,
);
$color-purple-300: map-get(
  $map: $color-purple,
  $key: 300,
);
$color-purple-400: map-get(
  $map: $color-purple,
  $key: 400,
);
$color-purple-500: map-get(
  $map: $color-purple,
  $key: 500,
);
$color-purple-600: map-get(
  $map: $color-purple,
  $key: 600,
);
$color-purple-700: map-get(
  $map: $color-purple,
  $key: 700,
);
$color-purple-800: map-get(
  $map: $color-purple,
  $key: 800,
);
$color-purple-900: map-get(
  $map: $color-purple,
  $key: 900,
);
$color-purple-a100: map-get(
  $map: $color-purple,
  $key: a100,
);
$color-purple-a200: map-get(
  $map: $color-purple,
  $key: a200,
);
$color-purple-a400: map-get(
  $map: $color-purple,
  $key: a400,
);
$color-purple-a700: map-get(
  $map: $color-purple,
  $key: a700,
);

// DEEP PURPLE
$color-deep-purple: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  a100: #b388ff,
  a200: #7c4dff,
  a400: #651fff,
  a700: #6200ea,
);

$color-deep-purple-50: map-get(
  $map: $color-deep-purple,
  $key: 50,
);
$color-deep-purple-100: map-get(
  $map: $color-deep-purple,
  $key: 100,
);
$color-deep-purple-200: map-get(
  $map: $color-deep-purple,
  $key: 200,
);
$color-deep-purple-300: map-get(
  $map: $color-deep-purple,
  $key: 300,
);
$color-deep-purple-400: map-get(
  $map: $color-deep-purple,
  $key: 400,
);
$color-deep-purple-500: map-get(
  $map: $color-deep-purple,
  $key: 500,
);
$color-deep-purple-600: map-get(
  $map: $color-deep-purple,
  $key: 600,
);
$color-deep-purple-700: map-get(
  $map: $color-deep-purple,
  $key: 700,
);
$color-deep-purple-800: map-get(
  $map: $color-deep-purple,
  $key: 800,
);
$color-deep-purple-900: map-get(
  $map: $color-deep-purple,
  $key: 900,
);
$color-deep-purple-a100: map-get(
  $map: $color-deep-purple,
  $key: a100,
);
$color-deep-purple-a200: map-get(
  $map: $color-deep-purple,
  $key: a200,
);
$color-deep-purple-a400: map-get(
  $map: $color-deep-purple,
  $key: a400,
);
$color-deep-purple-a700: map-get(
  $map: $color-deep-purple,
  $key: a700,
);

// INDIGO
$color-indigo: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  a100: #8c9eff,
  a200: #536dfe,
  a400: #3d5afe,
  a700: #304ffe,
);

$color-indigo-50: map-get(
  $map: $color-indigo,
  $key: 50,
);
$color-indigo-100: map-get(
  $map: $color-indigo,
  $key: 100,
);
$color-indigo-200: map-get(
  $map: $color-indigo,
  $key: 200,
);
$color-indigo-300: map-get(
  $map: $color-indigo,
  $key: 300,
);
$color-indigo-400: map-get(
  $map: $color-indigo,
  $key: 400,
);
$color-indigo-500: map-get(
  $map: $color-indigo,
  $key: 500,
);
$color-indigo-600: map-get(
  $map: $color-indigo,
  $key: 600,
);
$color-indigo-700: map-get(
  $map: $color-indigo,
  $key: 700,
);
$color-indigo-800: map-get(
  $map: $color-indigo,
  $key: 800,
);
$color-indigo-900: map-get(
  $map: $color-indigo,
  $key: 900,
);
$color-indigo-a100: map-get(
  $map: $color-indigo,
  $key: a100,
);
$color-indigo-a200: map-get(
  $map: $color-indigo,
  $key: a200,
);
$color-indigo-a400: map-get(
  $map: $color-indigo,
  $key: a400,
);
$color-indigo-a700: map-get(
  $map: $color-indigo,
  $key: a700,
);

// BLUE
$color-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  a100: #82b1ff,
  a200: #448aff,
  a400: #2979ff,
  a700: #2962ff,
);

$color-blue-50: map-get(
  $map: $color-blue,
  $key: 50,
);
$color-blue-100: map-get(
  $map: $color-blue,
  $key: 100,
);
$color-blue-200: map-get(
  $map: $color-blue,
  $key: 200,
);
$color-blue-300: map-get(
  $map: $color-blue,
  $key: 300,
);
$color-blue-400: map-get(
  $map: $color-blue,
  $key: 400,
);
$color-blue-500: map-get(
  $map: $color-blue,
  $key: 500,
);
$color-blue-600: map-get(
  $map: $color-blue,
  $key: 600,
);
$color-blue-700: map-get(
  $map: $color-blue,
  $key: 700,
);
$color-blue-800: map-get(
  $map: $color-blue,
  $key: 800,
);
$color-blue-900: map-get(
  $map: $color-blue,
  $key: 900,
);
$color-blue-a100: map-get(
  $map: $color-blue,
  $key: a100,
);
$color-blue-a200: map-get(
  $map: $color-blue,
  $key: a200,
);
$color-blue-a400: map-get(
  $map: $color-blue,
  $key: a400,
);
$color-blue-a700: map-get(
  $map: $color-blue,
  $key: a700,
);

// LIGHT BLUE
$color-light-blue: (
  50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  a100: #80d8ff,
  a200: #40c4ff,
  a400: #00b0ff,
  a700: #0091ea,
);

$color-light-blue-50: map-get(
  $map: $color-light-blue,
  $key: 50,
);
$color-light-blue-100: map-get(
  $map: $color-light-blue,
  $key: 100,
);
$color-light-blue-200: map-get(
  $map: $color-light-blue,
  $key: 200,
);
$color-light-blue-300: map-get(
  $map: $color-light-blue,
  $key: 300,
);
$color-light-blue-400: map-get(
  $map: $color-light-blue,
  $key: 400,
);
$color-light-blue-500: map-get(
  $map: $color-light-blue,
  $key: 500,
);
$color-light-blue-600: map-get(
  $map: $color-light-blue,
  $key: 600,
);
$color-light-blue-700: map-get(
  $map: $color-light-blue,
  $key: 700,
);
$color-light-blue-800: map-get(
  $map: $color-light-blue,
  $key: 800,
);
$color-light-blue-900: map-get(
  $map: $color-light-blue,
  $key: 900,
);
$color-light-blue-a100: map-get(
  $map: $color-light-blue,
  $key: a100,
);
$color-light-blue-a200: map-get(
  $map: $color-light-blue,
  $key: a200,
);
$color-light-blue-a400: map-get(
  $map: $color-light-blue,
  $key: a400,
);
$color-light-blue-a700: map-get(
  $map: $color-light-blue,
  $key: a700,
);

// CYAN
$color-cyan: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #26c6da,
  500: #00bcd4,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  a100: #84ffff,
  a200: #18ffff,
  a400: #00e5ff,
  a700: #00b8d4,
);

$color-cyan-50: map-get(
  $map: $color-cyan,
  $key: 50,
);
$color-cyan-100: map-get(
  $map: $color-cyan,
  $key: 100,
);
$color-cyan-200: map-get(
  $map: $color-cyan,
  $key: 200,
);
$color-cyan-300: map-get(
  $map: $color-cyan,
  $key: 300,
);
$color-cyan-400: map-get(
  $map: $color-cyan,
  $key: 400,
);
$color-cyan-500: map-get(
  $map: $color-cyan,
  $key: 500,
);
$color-cyan-600: map-get(
  $map: $color-cyan,
  $key: 600,
);
$color-cyan-700: map-get(
  $map: $color-cyan,
  $key: 700,
);
$color-cyan-800: map-get(
  $map: $color-cyan,
  $key: 800,
);
$color-cyan-900: map-get(
  $map: $color-cyan,
  $key: 900,
);
$color-cyan-a100: map-get(
  $map: $color-cyan,
  $key: a100,
);
$color-cyan-a200: map-get(
  $map: $color-cyan,
  $key: a200,
);
$color-cyan-a400: map-get(
  $map: $color-cyan,
  $key: a400,
);
$color-cyan-a700: map-get(
  $map: $color-cyan,
  $key: a700,
);

// TEAL
$color-teal: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #009688,
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  a100: #a7ffeb,
  a200: #64ffda,
  a400: #1de9b6,
  a700: #00bfa5,
);

$color-teal-50: map-get(
  $map: $color-teal,
  $key: 50,
);
$color-teal-100: map-get(
  $map: $color-teal,
  $key: 100,
);
$color-teal-200: map-get(
  $map: $color-teal,
  $key: 200,
);
$color-teal-300: map-get(
  $map: $color-teal,
  $key: 300,
);
$color-teal-400: map-get(
  $map: $color-teal,
  $key: 400,
);
$color-teal-500: map-get(
  $map: $color-teal,
  $key: 500,
);
$color-teal-600: map-get(
  $map: $color-teal,
  $key: 600,
);
$color-teal-700: map-get(
  $map: $color-teal,
  $key: 700,
);
$color-teal-800: map-get(
  $map: $color-teal,
  $key: 800,
);
$color-teal-900: map-get(
  $map: $color-teal,
  $key: 900,
);
$color-teal-a100: map-get(
  $map: $color-teal,
  $key: a100,
);
$color-teal-a200: map-get(
  $map: $color-teal,
  $key: a200,
);
$color-teal-a400: map-get(
  $map: $color-teal,
  $key: a400,
);
$color-teal-a700: map-get(
  $map: $color-teal,
  $key: a700,
);

// GREEN
$color-green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  a100: #b9f6ca,
  a200: #69f0ae,
  a400: #00e676,
  a700: #00c853,
);

$color-green-50: map-get(
  $map: $color-green,
  $key: 50,
);
$color-green-100: map-get(
  $map: $color-green,
  $key: 100,
);
$color-green-200: map-get(
  $map: $color-green,
  $key: 200,
);
$color-green-300: map-get(
  $map: $color-green,
  $key: 300,
);
$color-green-400: map-get(
  $map: $color-green,
  $key: 400,
);
$color-green-500: map-get(
  $map: $color-green,
  $key: 500,
);
$color-green-600: map-get(
  $map: $color-green,
  $key: 600,
);
$color-green-700: map-get(
  $map: $color-green,
  $key: 700,
);
$color-green-800: map-get(
  $map: $color-green,
  $key: 800,
);
$color-green-900: map-get(
  $map: $color-green,
  $key: 900,
);
$color-green-a100: map-get(
  $map: $color-green,
  $key: a100,
);
$color-green-a200: map-get(
  $map: $color-green,
  $key: a200,
);
$color-green-a400: map-get(
  $map: $color-green,
  $key: a400,
);
$color-green-a700: map-get(
  $map: $color-green,
  $key: a700,
);

// LIGHT GREEN
$color-light-green: (
  50: #f1f8e9,
  100: #dcedc8,
  200: #c5e1a5,
  300: #aed581,
  400: #9ccc65,
  500: #8bc34a,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  a100: #ccff90,
  a200: #b2ff59,
  a400: #76ff03,
  a700: #64dd17,
);

$color-light-green-50: map-get(
  $map: $color-light-green,
  $key: 50,
);
$color-light-green-100: map-get(
  $map: $color-light-green,
  $key: 100,
);
$color-light-green-200: map-get(
  $map: $color-light-green,
  $key: 200,
);
$color-light-green-300: map-get(
  $map: $color-light-green,
  $key: 300,
);
$color-light-green-400: map-get(
  $map: $color-light-green,
  $key: 400,
);
$color-light-green-500: map-get(
  $map: $color-light-green,
  $key: 500,
);
$color-light-green-600: map-get(
  $map: $color-light-green,
  $key: 600,
);
$color-light-green-700: map-get(
  $map: $color-light-green,
  $key: 700,
);
$color-light-green-800: map-get(
  $map: $color-light-green,
  $key: 800,
);
$color-light-green-900: map-get(
  $map: $color-light-green,
  $key: 900,
);
$color-light-green-a100: map-get(
  $map: $color-light-green,
  $key: a100,
);
$color-light-green-a200: map-get(
  $map: $color-light-green,
  $key: a200,
);
$color-light-green-a400: map-get(
  $map: $color-light-green,
  $key: a400,
);
$color-light-green-a700: map-get(
  $map: $color-light-green,
  $key: a700,
);

// LIME
$color-lime: (
  50: #f9fbe7,
  100: #f0f4c3,
  200: #e6ee9c,
  300: #dce775,
  400: #d4e157,
  500: #cddc39,
  600: #c0ca33,
  700: #afb42b,
  800: #9e9d24,
  900: #827717,
  a100: #f4ff81,
  a200: #eeff41,
  a400: #c6ff00,
  a700: #aeea00,
);

$color-lime-50: map-get(
  $map: $color-lime,
  $key: 50,
);
$color-lime-100: map-get(
  $map: $color-lime,
  $key: 100,
);
$color-lime-200: map-get(
  $map: $color-lime,
  $key: 200,
);
$color-lime-300: map-get(
  $map: $color-lime,
  $key: 300,
);
$color-lime-400: map-get(
  $map: $color-lime,
  $key: 400,
);
$color-lime-500: map-get(
  $map: $color-lime,
  $key: 500,
);
$color-lime-600: map-get(
  $map: $color-lime,
  $key: 600,
);
$color-lime-700: map-get(
  $map: $color-lime,
  $key: 700,
);
$color-lime-800: map-get(
  $map: $color-lime,
  $key: 800,
);
$color-lime-900: map-get(
  $map: $color-lime,
  $key: 900,
);
$color-lime-a100: map-get(
  $map: $color-lime,
  $key: a100,
);
$color-lime-a200: map-get(
  $map: $color-lime,
  $key: a200,
);
$color-lime-a400: map-get(
  $map: $color-lime,
  $key: a400,
);
$color-lime-a700: map-get(
  $map: $color-lime,
  $key: a700,
);

// YELLOW
$color-yellow: (
  50: #fffde7,
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #ffee58,
  500: #ffeb3b,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  a100: #ffff8d,
  a200: #ffff00,
  a400: #ffea00,
  a700: #ffd600,
);

$color-yellow-50: map-get(
  $map: $color-yellow,
  $key: 50,
);
$color-yellow-100: map-get(
  $map: $color-yellow,
  $key: 100,
);
$color-yellow-200: map-get(
  $map: $color-yellow,
  $key: 200,
);
$color-yellow-300: map-get(
  $map: $color-yellow,
  $key: 300,
);
$color-yellow-400: map-get(
  $map: $color-yellow,
  $key: 400,
);
$color-yellow-500: map-get(
  $map: $color-yellow,
  $key: 500,
);
$color-yellow-600: map-get(
  $map: $color-yellow,
  $key: 600,
);
$color-yellow-700: map-get(
  $map: $color-yellow,
  $key: 700,
);
$color-yellow-800: map-get(
  $map: $color-yellow,
  $key: 800,
);
$color-yellow-900: map-get(
  $map: $color-yellow,
  $key: 900,
);
$color-yellow-a100: map-get(
  $map: $color-yellow,
  $key: a100,
);
$color-yellow-a200: map-get(
  $map: $color-yellow,
  $key: a200,
);
$color-yellow-a400: map-get(
  $map: $color-yellow,
  $key: a400,
);
$color-yellow-a700: map-get(
  $map: $color-yellow,
  $key: a700,
);

// AMBER
$color-amber: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  a100: #ffe57f,
  a200: #ffd740,
  a400: #ffc400,
  a700: #ffab00,
);

$color-amber-50: map-get(
  $map: $color-amber,
  $key: 50,
);
$color-amber-100: map-get(
  $map: $color-amber,
  $key: 100,
);
$color-amber-200: map-get(
  $map: $color-amber,
  $key: 200,
);
$color-amber-300: map-get(
  $map: $color-amber,
  $key: 300,
);
$color-amber-400: map-get(
  $map: $color-amber,
  $key: 400,
);
$color-amber-500: map-get(
  $map: $color-amber,
  $key: 500,
);
$color-amber-600: map-get(
  $map: $color-amber,
  $key: 600,
);
$color-amber-700: map-get(
  $map: $color-amber,
  $key: 700,
);
$color-amber-800: map-get(
  $map: $color-amber,
  $key: 800,
);
$color-amber-900: map-get(
  $map: $color-amber,
  $key: 900,
);
$color-amber-a100: map-get(
  $map: $color-amber,
  $key: a100,
);
$color-amber-a200: map-get(
  $map: $color-amber,
  $key: a200,
);
$color-amber-a400: map-get(
  $map: $color-amber,
  $key: a400,
);
$color-amber-a700: map-get(
  $map: $color-amber,
  $key: a700,
);

// ORANGE
$color-orange: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  a100: #ffd180,
  a200: #ffab40,
  a400: #ff9100,
  a700: #ff6d00,
);

$color-orange-50: map-get(
  $map: $color-orange,
  $key: 50,
);
$color-orange-100: map-get(
  $map: $color-orange,
  $key: 100,
);
$color-orange-200: map-get(
  $map: $color-orange,
  $key: 200,
);
$color-orange-300: map-get(
  $map: $color-orange,
  $key: 300,
);
$color-orange-400: map-get(
  $map: $color-orange,
  $key: 400,
);
$color-orange-500: map-get(
  $map: $color-orange,
  $key: 500,
);
$color-orange-600: map-get(
  $map: $color-orange,
  $key: 600,
);
$color-orange-700: map-get(
  $map: $color-orange,
  $key: 700,
);
$color-orange-800: map-get(
  $map: $color-orange,
  $key: 800,
);
$color-orange-900: map-get(
  $map: $color-orange,
  $key: 900,
);
$color-orange-a100: map-get(
  $map: $color-orange,
  $key: a100,
);
$color-orange-a200: map-get(
  $map: $color-orange,
  $key: a200,
);
$color-orange-a400: map-get(
  $map: $color-orange,
  $key: a400,
);
$color-orange-a700: map-get(
  $map: $color-orange,
  $key: a700,
);

// DEEP ORANGE
$color-deep-orange: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #ff5722,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  a100: #ff9e80,
  a200: #ff6e40,
  a400: #ff3d00,
  a700: #dd2c00,
);

$color-deep-orange-50: map-get(
  $map: $color-deep-orange,
  $key: 50,
);
$color-deep-orange-100: map-get(
  $map: $color-deep-orange,
  $key: 100,
);
$color-deep-orange-200: map-get(
  $map: $color-deep-orange,
  $key: 200,
);
$color-deep-orange-300: map-get(
  $map: $color-deep-orange,
  $key: 300,
);
$color-deep-orange-400: map-get(
  $map: $color-deep-orange,
  $key: 400,
);
$color-deep-orange-500: map-get(
  $map: $color-deep-orange,
  $key: 500,
);
$color-deep-orange-600: map-get(
  $map: $color-deep-orange,
  $key: 600,
);
$color-deep-orange-700: map-get(
  $map: $color-deep-orange,
  $key: 700,
);
$color-deep-orange-800: map-get(
  $map: $color-deep-orange,
  $key: 800,
);
$color-deep-orange-900: map-get(
  $map: $color-deep-orange,
  $key: 900,
);
$color-deep-orange-a100: map-get(
  $map: $color-deep-orange,
  $key: a100,
);
$color-deep-orange-a200: map-get(
  $map: $color-deep-orange,
  $key: a200,
);
$color-deep-orange-a400: map-get(
  $map: $color-deep-orange,
  $key: a400,
);
$color-deep-orange-a700: map-get(
  $map: $color-deep-orange,
  $key: a700,
);

// BROWN
$color-brown: (
  50: #efebe9,
  100: #d7ccc8,
  200: #bcaaa4,
  300: #a1887f,
  400: #8d6e63,
  500: #795548,
  600: #6d4c41,
  700: #5d4037,
  800: #4e342e,
  900: #3e2723,
);

$color-brown-50: map-get(
  $map: $color-brown,
  $key: 50,
);
$color-brown-100: map-get(
  $map: $color-brown,
  $key: 100,
);
$color-brown-200: map-get(
  $map: $color-brown,
  $key: 200,
);
$color-brown-300: map-get(
  $map: $color-brown,
  $key: 300,
);
$color-brown-400: map-get(
  $map: $color-brown,
  $key: 400,
);
$color-brown-500: map-get(
  $map: $color-brown,
  $key: 500,
);
$color-brown-600: map-get(
  $map: $color-brown,
  $key: 600,
);
$color-brown-700: map-get(
  $map: $color-brown,
  $key: 700,
);
$color-brown-800: map-get(
  $map: $color-brown,
  $key: 800,
);
$color-brown-900: map-get(
  $map: $color-brown,
  $key: 900,
);

// GRAY
$color-gray: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
);

$color-gray-50: map-get(
  $map: $color-gray,
  $key: 50,
);
$color-gray-100: map-get(
  $map: $color-gray,
  $key: 100,
);
$color-gray-200: map-get(
  $map: $color-gray,
  $key: 200,
);
$color-gray-300: map-get(
  $map: $color-gray,
  $key: 300,
);
$color-gray-400: map-get(
  $map: $color-gray,
  $key: 400,
);
$color-gray-500: map-get(
  $map: $color-gray,
  $key: 500,
);
$color-gray-600: map-get(
  $map: $color-gray,
  $key: 600,
);
$color-gray-700: map-get(
  $map: $color-gray,
  $key: 700,
);
$color-gray-800: map-get(
  $map: $color-gray,
  $key: 800,
);
$color-gray-900: map-get(
  $map: $color-gray,
  $key: 900,
);

// BLUE GRAY
$color-blue-gray: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
);

$color-blue-gray-50: map-get(
  $map: $color-blue-gray,
  $key: 50,
);
$color-blue-gray-100: map-get(
  $map: $color-blue-gray,
  $key: 100,
);
$color-blue-gray-200: map-get(
  $map: $color-blue-gray,
  $key: 200,
);
$color-blue-gray-300: map-get(
  $map: $color-blue-gray,
  $key: 300,
);
$color-blue-gray-400: map-get(
  $map: $color-blue-gray,
  $key: 400,
);
$color-blue-gray-500: map-get(
  $map: $color-blue-gray,
  $key: 500,
);
$color-blue-gray-600: map-get(
  $map: $color-blue-gray,
  $key: 600,
);
$color-blue-gray-700: map-get(
  $map: $color-blue-gray,
  $key: 700,
);
$color-blue-gray-800: map-get(
  $map: $color-blue-gray,
  $key: 800,
);
$color-blue-gray-900: map-get(
  $map: $color-blue-gray,
  $key: 900,
);

/*
 * =================================================================
 * ||                        COLORS THEME                         ||
 * =================================================================
 */

$color-primary-light: $color-blue-300;
$color-primary: $color-blue-500;
$color-primary-dark: $color-blue-700;
$color-primary-dark-blue: $color-blue-900;

$color-accent-light: $color-orange-a200;
$color-accent: $color-orange-500;
$color-accent-dark: $color-orange-a700;

// STATUS
$color-danger: $color-red-a200;
$color-danger-dark: $color-red-500;

$color-warning: $color-amber-500;

$color-success: $color-green-500;
$color-success-light: #aedd7f;

// GRAYS/BLACK
$color-black: $color-black;
$color-black-1: $color-gray-300; // $color-gray-1
$color-black-2: $color-gray-500; // $color-gray-2
$color-black-3: $color-gray-600; // $color-gray-3
$color-black-4: $color-gray-800; // $color-gray-4
$color-black-5: $color-gray-900; // $color-gray-5

// WHITE
$color-white: $color-white;
$color-white-dark: $color-grey-50;

// Text recommendation black
$color-text-primary: $color-black-5; // rgba($color-black, .87);
$color-text-secondary: $color-black-4; // rgba($color-black, .60);
$color-text-hint: $color-black-3; // rgba($color-black, .38);color-dividers: $color-black-2; // rgba($color-black, .12);

// Text recommendation white
$color-text-primary-white: $color-text-primary-white;
$color-text-secondary-white: $color-text-secondary-white;
$color-text-hint-white: $color-text-hint-white;
$color-dividers: $color-dividers-white;
