.MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
  height: 40px;
}

.MuiCardContent-root:last-child {
  padding-bottom: 16px !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 8px 0 !important;
}

.MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.MuiChip-root {
  height: 20px !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
