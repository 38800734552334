$space: 8px;
$space-1: 8px;
$space-2: $space-1 * 2;
$space-3: $space-1 * 3;
$space-4: $space-1 * 4;
$space-5: $space-1 * 5;
$space-6: $space-1 * 6;
$space-7: $space-1 * 7;
$space-8: $space-1 * 8;
$space-9: $space-1 * 9;
$space-10: $space-1 * 10;
